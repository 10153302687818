// Deps
import { useState, useEffect, useRef, useCallback } from 'react'

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'
import Listing from '/views/sections/listing'
import ProductCarousel from '/views/sections/product-carousel'

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';

// Hooks
import useQuery from '/hooks/query'

// Services
import productServices from '/services/product'
import siteServices from '/services/site'

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state'
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';

// Shared Endpoints
const getProductData = (id, query) => {
	return productServices.getCustomListProducts(id, query);
}

const getCategoryFilters = (listID, inStock) => {
	return productServices.getCategoryFilters(listID, inStock);
}

const getRecentProducts = () => {
	return siteServices.getLastVisitedProducts();
}

const generateHead = (listData, getRoutePath) => {
	return {
		title: listData.seo_title ? listData.seo_title : listData.short_title,
		description: listData.seo_description ? listData.seo_description : `"${listData.title}" temalı ürünler.`,
		canonical: getRoutePath('customList', { id: listData.id, slug: listData.url }),
	}
}

const defaultQuery = {
	page: '1',
	order: 'ovd',
}

const CustomList = ({ match }) => {
	const _mounted = useRef(false);
	
	const { setError, setNotFound } = useGlobalState();
	const { getRoutePath, redirect } = useNavigator();
	const { triggerEvent } = useGlobalEvents();
	const { setHead } = useHead();

	const { id: listID, query: queryString, slug: listSlug } = match.params;
	
	const paramsCache = useRef(match.params);
	
	const [query] = useQuery(queryString, defaultQuery, undefined, undefined, undefined, listID);
	
	const [filters, setFilters] = useState(null);
	const [pagination, setPagination] = useState(false);
	const [products, setProducts] = useState(false);
	const [listData, setListData] = useState(false);
	
	// Supporting Data
	const [recentProducts, setRecentProducts] = useState(false);
	
	const fetchedList = useRef(false);
	const fetchedQuery = useRef(false);

	const getData = (id, query, slug) => {
		setProducts(false);
		setListData(false);

		getProductData(id, query).then((payload) => {
			if (_mounted.current) {
				if(payload.listData.url !== slug) {
					redirect({
						to: 'customList',
						type: 'replace',
					},
					{
						slug: payload.listData.url,
						id: id,
					},
					false);
				}

				fetchedList.current = id;
				fetchedQuery.current = query;
				setPagination(payload.pagination);
				setProducts(payload.products);
				setListData(payload.listData);
			}
		}).catch((status) => {
			if (_mounted.current) {
				console.warn('Custom List products fetch error', status);
				if(status === 404) {
					setNotFound();
				}
				else {
					setError();
				}
			}
		})
	};

	const getFilters = (id, inStock) => {
		setFilters(false);
		getCategoryFilters(id, inStock).then((payload) => {
			if (_mounted.current) {
				setFilters({ id: id, inStock: inStock, filters: payload});
			}
		}).catch((e) => {
			if (_mounted.current) {
				console.warn('Category filters fetch error', e);
				if(e.status === 404) {
					setNotFound();
				}
				else {
					setError();
				}
			}
		})
	}

	const updateQuery = useCallback((updatedQuery) => {
		let newQuery = [];

		for (const param in updatedQuery) {
			if (param !== 'primaryVariable' && updatedQuery[param].length && defaultQuery[param] !== updatedQuery[param]) {
				newQuery.push(param + '=' + updatedQuery[param]);
			}
		}

		redirect(
			'customList',
			{
				...paramsCache.current,
				query: (newQuery.length ? newQuery.join('&') : undefined),
			},
			undefined,
			undefined,
			false
		);
	}, []);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {
		paramsCache.current = match.params;
	}, [match.params])
	
	useEffect(() => {
		if(query?.primaryVariable && listSlug) {
			const {primaryVariable, ...newQuery} = query;
	
			const idChanged = primaryVariable !== fetchedList.current
			if (!isExact(newQuery, fetchedQuery.current) || idChanged) {
				getData(primaryVariable, newQuery, listSlug);
	
				if (idChanged) {
					setPagination(false);
					// getFilters(primaryVariable);
				}
			}
		}
	}, [query, listSlug])

	useEffect(() => {
		if(query.primaryVariable && filters !== false && !(filters?.id === query.primaryVariable && (query.stock === "1") === filters?.inStock)) {
			getFilters(query.primaryVariable, query.stock === "1");
		}
	}, [filters, query])

	useEffect(() => {
		if(listData) {
			setHead(generateHead(listData, getRoutePath))
		}
	}, [listData])

	// Supporting Data
	useEffect(() => {
		if (_mounted.current && !recentProducts) {
			getRecentProducts().then((payload) => {
				if (_mounted.current) {
					setRecentProducts(payload);
				}
			}).catch(() => {
			})
		}
	}, [recentProducts]);

	// Analytics
	useEffect(() => {
		if(listData) {
			triggerEvent('listingDataUpdate', {
				key: `Liste Sayfası: ${listData.short_title}`,
				products,
				params: {
					taxonomy: [`Liste: ${listData.short_title}`]
				},
			});
		}
	}, [products, listData])


	return (
		<main className="page listing">
			<Breadcrumbs data={listData ? [{name: listData.title}] : []} />
			<Listing
				page="customList"
				onUpdateQuery={updateQuery}
				query={query}
				products={products}
				pagination={pagination}
				banners={(listData && listData.banner) ? [{
					id: 1,
					link: false,
					title: listData.short_title,
					image_url: listData.banner,
					type: 8,
				}] : undefined}
				// filters={filters?.filters ?? false}
				title={listData ? listData.short_title : false}
				description={listData ? listData.title : false} />
			<ProductCarousel
				tabs
				className="listing-recentproducts"
				data={[
					{
						title: 'Son Gezilen Ürünler',
						icon: 'history',
						key: 'recent',
						products: recentProducts ? recentProducts : false,
					}
				]} />
		</main>
	)
}

CustomList.defaultProps = {
}

export default CustomList;